<template>
    <div v-if="items.length > 0 " class="mt-4 card mb-6">
        <div class="card-header">
            <h3 class="card-title">Frequently Bought Together</h3>
        </div>
        <div class="card-body row">
            <div v-for="item in items" v-bind:key="item.groupId" class="col-lg-3 col-md-4 col-sm-6">
                <div class="card mb-4">
                    <img :src="item.productImage" class="card-img-top" :alt="'Product Image of '+item.name">
                    <div class="card-footer">
                        <a :href="`/products/${item.group}/${item.sku}`">{{ item.name }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import api from "@/services/api";

export default {
    name: "FBT",
    data() {
        return  {
            items: []
        }
    },
    async mounted() {
        const itemsResp = await api.get('/api/fbt/sku/' + this.$route.params.productId)
        this.items = itemsResp.data;
        console.log("Items",this.items)
    }
}
</script>